@import "main-bx";

.section {
    &_slider {
        overflow-x: hidden;

        @media (max-width: 768px) {
            overflow: visible;
        }
    } 
    &__01 {
        padding-top: 80px;
        padding-bottom: 100px;

        @media (max-width: 768px) {
            padding-bottom: 40px;
        }
    }

    &__02 {
        padding-top: 20px;
        padding-bottom: 120px;

        @media (max-width: 768px) {
            padding-top: 0;
            padding-bottom: 50px;
        }
    }

    &__03 {
        padding-top: 100px;
        padding-bottom:20px;
    
        .section__title {
            text-align: left;
        }

        
        @media (max-width: 768px) {
            padding-top: 40px;
            padding-bottom: 0;
            .section__row {
                flex-direction: row;
            }
        }

        &_last {
            padding-bottom: 100px;

            @media (max-width: 768px) {
                padding-bottom: 50px;
            }
        }

    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__title {
        @include fontProperty(800, 40px, 55px, $color_dark);
        text-align: center;

        &_wrap {
            width: 100%;
            max-width: 680px;
            text-align: left;
        }

        @media (max-width: 1320px) {
            font-size: 30px;
        }

        @media (max-width: 768px) {
            font-size: 20px;
            line-height: 27px;
        }
    }

    &__text {
        @include fontProperty(400, 18px, 25px, $color_dark);
        text-align: center;
    }

    &__btn {
        display: flex;
        align-items: center;
        padding: 18px 30px 15px;
        img {
            width: 16px;
            height: 16px;
            margin-left: 30px;
            margin-top: -3px;
        }

        @media (max-width: 768px) {
            margin-top: 20px;
        }
    }
}