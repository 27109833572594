@import "main-bx";

.promo {
    border-radius: 2px;

    background: #FFFFFF;
    box-shadow: 0px 6px 20px -10px #D1D3D4;

    &__img {
        height: 240px;
    }

    &__body {
        padding: 25px 30px 40px;
        height: 270px;
        position: relative;
    }

    &__date {

        display: inline-block;
        padding: 5px 10px;
        border: 1px solid #D1D3D4;
        border-radius: 30px;

        @include fontProperty(400, 12px, 120%, $color_dark3);
        margin-bottom: 10px;
    }

    &__title {
        @include fontProperty(700, 20px, 120%, $color_dark);

        @media (max-width: 768px) {
            padding-right: 10px;
        }
    }

    &__text {
        margin-top: 15px;
    }

    &__btn {
        @include fontProperty(700, 14px, 19px, $color_Bred);
        letter-spacing: 0.05em;
        text-transform: uppercase;
        transition: all .2s linear;

        position: absolute;
        left: 30px;
        bottom: 40px;
        display: flex;
        align-items: center;
        svg {
            width: 16px;
            height: 16px;
            fill: currentColor;
            margin-left: 12px;
        }
    }
}
