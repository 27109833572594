@import "main-bx";

*, *::before, *::after {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, ul, p {
    margin: 0;
    padding: 0;
}

.container {
    width: 100%;
    max-width: map-get($container, xl);
    margin: 0 auto;

    @include below(1320px) {
        max-width: 1100px;
    }

    @include below(1124px) {
        padding-right: 20px;
        padding-left: 20px;
    }

    @include below(769px) {
        max-width: 375px;
        padding-right: 15px;
        padding-left: 15px;
    }

    @include below(374px) {
        max-width: 100%;
    }
}

body {
    position: relative;
    font-family: $accent_font;
    font-size: 16px;
    background-color: $white;
    width: 100%;
    max-width: $body_width;
    margin: 0 auto !important;
    color: $color_dark;
    
    &.hidden {
        overflow: hidden;
    }
}

.main {
    width: 100%;
    @include below(1120px) {
        overflow: hidden;
    }
} 


ul {
    list-style-type: none;
}

a {
    text-decoration: none;
}

:focus {
    outline-style: none;
    outline-width: 0 !important;
    outline-color: none !important;
}

input,
button,
textarea,
select {
    background: transparent;
    border: none;
    padding: 0;
}

button {
    cursor: pointer;
}

img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

svg {
    transition: all .2s linear;
}


.lds-dual-ring {
    display: inline-block;
    width: 40px;
    height: 40px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 40px;
    height: 40px;
    margin: 6px 0;
    border-radius: 50%;
    border: 4px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
  