@import "main-bx";

.header {
    background-color: #212322;
    &__inner {
        padding: 27px 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 768px) {
            width: 100%;
            max-width: 375px;
            margin: 0 auto;
            padding: 20px 15px;
        }

        @media (max-width: 420px) {
            position: relative;
        }
    }

    &__logo {
        width: 100%;
        max-width: 180px;
        height: 36px;
        display: block;

        @media (max-width: 1200px) {
            max-width: 120px;
            height: 24px;
        }

        @media (max-width: 350px) {
            max-width: 100px;
            height: auto;
        }
    }

    &__nav {
        margin-right: auto;
        margin-left: 100px;

        @media (max-width: 1440px) {
            margin-left: 20px;
        }

        @media (max-width: 1100px) {
            position: absolute;
            top: 80px;
            right: 0;
            z-index: 1000;
            background-color: #212322;
            width: 100%;
            max-width: 320px;
            height: 100vh;
            padding-top: 50px;
            transition: all .3s linear;
            display: none;
            &.active {
               display: block;
            }
        }

        @media (max-width: 768px) {
            top: 62px;
        }

        @media (max-width: 420px) {
            max-width: 100%;
        }
    }

    &__contacts {
        display: flex;
        align-items: center;

        a {
            font-weight: 800;
            font-size: 24px;
            line-height: 33px;
            color: #fff;
            transition: all .2s linear;
            border-bottom: 1px solid #fff;
            text-transform: uppercase;

            &:hover {
                color: #A50034;
                border-color: #A50034;
            }

            @media (max-width: 1440px) {
                font-size: 18px;
            }

            @media (max-width: 768px) {
                font-size: 12px;
                line-height: 16px;
            }
        
            @media (max-width: 350px) {
                font-size: 10px;
            }
        }
    
        &-btn {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #fff;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            cursor: pointer;
            transition: all .2s linear;
            padding-left: 40px;
            margin-left: 40px;

            @media (max-width: 1440px) {
                padding-left: 20px;
                margin-left: 20px;
            }

            @media (max-width: 768px) {
                padding-left: 0;
                margin-left: 15px;
            }

            &::before {
                content: "";
                position: absolute;
                top: -30px;
                left: 0;
                width: 1px;
                height: 94px;
                background-color: rgba(157, 166, 171, 0.1);

                @media (max-width: 768px) {
                    content: none;
                }
            }

            &:hover {
                color: #A50034;
                .header__contacts-phone {
                    background-color: #A50034;
                }

                .header__contacts-phone svg {
                    fill: #fff;
                }
            }

            span {
                @media (max-width: 1100px) {
                    display: none;
                }
            }
        }
    
        &-phone,
        .header__burger {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #A7A9AC;
            margin-right: 12px;
            transition: all .2s linear;

            @media (max-width: 768px) {
                width: 24px;
                height: 24px;
            }

            svg {
                width: 16px;
                height: 16px;
                fill: #212322;
                transition: all .2s linear;
            
                @media (max-width: 768px) {
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }

    &__burger {
        display: none !important;
        @media (max-width: 1100px) {
            display: flex !important;
        }

        &:hover,
        &:focus,
        &.active {
            background-color: #A50034;

            svg {
                fill: #fff;
            }
        }

        &.active {
            .icon-more {
                display: none;
            }

            .icon-close {
                display: block;
            }
        }

        .icon-close {
            display: none;
        }
        svg {
            width: 14px;
            height: 14px;
            fill: #212322;
            transition: all .2s linear;
        }
    }
}