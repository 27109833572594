@import "main-bx";

.service {
    &__inner {
        display: flex;
        flex-wrap: wrap;
        padding-top: 50px;
        margin: -50px 0 0 -50px;

        @media (max-width: 1320px) {
            margin-left: 0;
            justify-content: space-between;
        }
        
        @media (max-width: 768px) {
            padding-top: 25px;
            margin-top: -20px;
        }
    }

    &__item {
        position: relative;
        width: 100%;
        max-width: 360px;
        height: 380px;
        padding: 50px 45px 40px 40px;
        margin: 50px 0 0 50px;

        background-color: #FBFBFB;
        box-shadow: 0px 10px 40px rgba(157, 166, 171, 0);
        border-radius: 3px;
        transition: all .2s linear;

        &:hover {
            background-color: #fff;
            box-shadow: 0px 10px 40px rgba(157, 166, 171, 0.2);
        }

        &.show {
            background-color: #fff;
            box-shadow: -5px 10px 40px rgba(0,0,0,0.20);
            .service__list {
                display: block;
            }

            @media (max-width: 768px) {
                box-shadow: 10px -5px 40px rgba(157, 166, 171, 0.2);
            }
        }

        @media (max-width: 1320px) {
            max-width: 47%;
            margin-left: 0;
        }

        @media (max-width: 768px) {
            max-width: 100%;

            padding: 30px 20px;
            display: flex;
            height: 200px;
            margin-top: 20px;
        }

        &_left {
            .service__list {
                left: auto;
                right: 100%;

                @media (max-width: 768px) {
                    right: 0;
                }
            }

            &.show {
                box-shadow: 5px 10px 40px rgba(0,0,0,0.20);
                .service__list {
                    box-shadow: -40px 0px 40px rgba(157, 166, 171, 0.2);
                
                    @media (max-width: 768px) {
                        box-shadow: 0px 40px 40px rgba(157, 166, 171, 0.2);
                    }
                }
            }
        }

        &:nth-child(odd) {
            @media (max-width: 1320px) {
                .service__list {
                    left: 100%;
                    right: auto;
                    box-shadow: 40px 0px 40px rgba(157, 166, 171, 0.2);
                
                    @media (max-width: 768px) {
                        left: 0;
                    }
                }
                
            }
        } 

        &:nth-child(even) {
            @media (max-width: 1320px) {
                .service__list {
                    left: auto;
                    right: 100%;

                    @media (max-width: 768px) {
                        right: 0;
                    }
                }
    
                &.show {
                    box-shadow: 5px 10px 40px rgba(0,0,0,0.20);
                    .service__list {
                        box-shadow: -40px 0px 40px rgba(157, 166, 171, 0.2);
                    
                        @media (max-width: 768px) {
                            box-shadow: 0px 40px 40px rgba(157, 166, 171, 0.2);
                        }
                    }
                }
            }
        }

        &-icon {
            width: 100%;
            max-width: 60px;
            max-width: 60px;
            height: 60px;
            margin-bottom: 40px;

            @media (max-width: 768px) {
                margin-bottom: 0;
                margin-right: 20px;
            }
        }

        &-title {
            @include fontProperty(700, 20px, 27px, $color_dark);
            margin-bottom: 16px;

            @media (max-width: 768px) {
                font-size: 16px;
                line-height: 22px;
                margin-bottom: 5px;
            }
        }

        &-text {
            @include fontProperty(400, 16px, 120%, $color_dark);

            @media (max-width: 768px) {
                font-size: 12px;
                line-height: 120%;
            }
        }
    }

    &__btn {
        position: absolute;
        bottom: 40px;
        left: 40px;
        transition: all .2s linear;
        display: flex;
        align-items: center;

        @include fontProperty(700, 16px, 22px, $color_grey);
        text-transform: uppercase;

        @media (max-width: 768px) {
            bottom: 30px;
            left: 20px;
        }

        &:hover {
            color: $color_Bred;

            svg {
                stroke: currentColor;
            }
        }

        &.active {
            .service__btn-icon {
                background-color: $color_Bred;
            }
            svg {
                stroke: $white;
            }
        }

        &-icon {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid currentColor;
            border-radius: 50%;
            margin-right: 12px;
            svg {
                width: 16px;
                height: 16px;
                stroke: #6D6E71;
            }
        } 
    }

    &__list {
        display: none;
        position: absolute;
        top: 0;
        left: 100%;
        z-index: 100;
        width: 100%;
        min-width: 410px;
        height: 100%;
        padding: 50px 40px 40px 50px;
        background: #FFFFFF;
        box-shadow: 40px 0px 40px rgba(157, 166, 171, 0.2);
        border-radius: 3px;

        li {
            padding-bottom: 15px;
            display: flex;
            align-items: center;
            &::before {
                content: "";
                display: block;
                min-width: 8px;
                max-width: 8px;
                height: 8px;
                background-color: #A50034;
                margin-right: 8px;
                border-radius: 50%
            }
        }

        a {
            @include fontProperty(400, 16px, 120%px, $color_dark2);
            text-decoration-line: underline;
            transition: all .2s linear;
            &:hover {
                color: $color_Bred;
            }
        }

        @media (max-width: 768px) {
            min-width: 100%;
            left: 0;
            top: 200px;
            height: auto;

            padding: 20px;
        }
    }
}