@import "main-bx";

.upload {
    margin-bottom: 150px;

    @media (max-width: 768px) {
        margin-bottom: 40px;
    }

    &__wrap {
        background: #FFFFFF;
        box-shadow: 0px 10px 40px rgba(157, 166, 171, 0.2);
        border-radius: 20px;
        padding: 60px;

        @media (max-width: 768px) {
            padding: 25px 20px;
        }
    }

    &__title {
        @include fontProperty(400, 32px, 120%, $color_dark);
        margin-bottom: 20px;
    
        @media (max-width: 768px) {
            font-size: 20px;
            text-align: center;
        }
    }

    &__files {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        
        @media (max-width: 768px) {
            align-self: flex-start;
                
            width: 100%;
            padding-right: 20px;
        }

        &-item {
            position: relative;
            width: 100%;
            min-width: 66px;
            max-width: 66px;
            height: 66px;
            margin-right: 25px;
            img {
                border-radius: 12px;
            }
        }

        &-clear {
            position: absolute;
            top: 1px;
            right: 0;
            width: 30px;
            height: 30px;
            
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: #A50034;

            border-top-right-radius: 12px;
            border-bottom-left-radius: 12px;

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }


    &__form {
        &-row {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            &:not(:last-child) {
                margin-bottom: 30px;
            }

            &_files {
                justify-content: flex-start;
            }

            @media (max-width: 768px) {
                flex-direction: column;

                &:last-child {
                    margin-top: -25px;
                }
            }
        }

        &-label {
            width: 32%;
            input,
            select {
                width: 100%;
            }

            @media (max-width: 768px) {
                width: 100%;
                margin-bottom: 20px;
            }
        }

        .form__check {
            padding: 0;

            @media (max-width: 768px) {
                margin-bottom: 20px;
            }
        }

        &-btn {
            padding: 18px 30px 15px;
        }
    
        .form__upload {
            @media (max-width: 768px) {
                flex-direction: column;
            }    
        }
        .form__upload-max {
            @media (max-width: 768px) {
                margin-left: 0;
                margin-top: 10px;
            }
        }

        .form__upload-max_alert,
        .upload__error {
            @media (max-width: 768px) {
                right: 0;
                bottom: -15px;

                text-align: center;
            }
        }

        .setter {
            @media (max-width: 768px) {
                position: absolute;
                right: -150px;
                bottom: 0;
            }
        }
    }

    &__attached {
        display: none;
        @include fontProperty(700, 14px, 19px, $color_Bred);
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-right: 25px;

        @media (max-width: 768px) {
            margin-bottom: 10px;
            br {
                display: none;
            }
        }
    }

    &__error {
        position: absolute;
        left: 0;
        bottom: -20px;
        display: none;
        
        @include fontProperty(400, 12px, 100%, $color_Bred);
    }
}