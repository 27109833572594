@import "main-bx";
 

.btn {
    @include fontProperty(700, 16px, 22px, $white);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    border-radius: 55px;
    transition: all .2s linear;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;

    box-shadow: none;

    @include below(768px) {
        width: 100%;
        justify-content: center;
    }

    &_red {
        background-color: #A50034;
        &:hover {
            background-color: #D10042;
            box-shadow: 0px 0px 30px rgba(209, 0, 66, 0.3);
        }

        &:active {
            background-color: #92002E;
        }
    }

    &:disabled {
        span {
            opacity: 0;
        }
        .btn__spin {
            opacity: 1;
        }

        &:hover,
        &:active {
            // background-color: #65f38d;
        }
    }

    &__spin {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        opacity: 0;
    } 

}
