@import "main-bx";

.intro {
    position: relative;
    background-image: url(/dist/img/content/bg1.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 750px;
    margin-bottom: 70px;

    @include below(1440px) {
        height: 600px;

        margin-bottom: 100px;
    }

    @include below(768px) {
        height: 357px;
        margin-bottom: 530px;
    }

    &__inner {
        position: relative;
        height: 100%;
       
        @include below(768px) {
            max-width: 100%;
        }
    }

    &__content {
        position: absolute;
        top: 180px;
        left: 0;

        width: 100%;
        max-width: 700px;

        @include below(1440px) {
            top: 120px
        }

        @include below(1320px) {
            padding-left: 25px;
        }

        @include below(768px) {
            top: auto;
            right: 0;
            max-width: 100%;
            padding-left: 0;
            bottom: -335px;
            background-color: #212322;
            padding: 20px 15px 140px 15px;
        }
    
        &-inner {
            @include below(768px) {
                max-width: 375px;
                margin: 0 auto;
            }
        }
    }

    &__title {
        @include fontProperty(800, 54px, 120%, $white);
        margin-bottom: 16px;

        @include below(1440px) {
            font-size: 45px;
        }

        @include below(768px) {
            font-size: 27px;
            text-align: center;
            margin-bottom: 10px;
        }
    }

    &__text {
        @include fontProperty(400, 32px, 120%, $white);
        margin-bottom: 40px;

        @include below(1440px) {
            font-size: 28px;
        }

        @include below(768px) {
            font-size: 16px;
            margin-bottom: 30px;
            text-align: center;
        }
    }

    &__btn {
        padding: 20px 42px;

        @include below(768px) {
            padding: 15px 0;
        }
    }

    &__bottom {
        position: absolute;
        width: 100%;
        bottom: -100px;
        left: 0;
        right: 0;
        background-color: $white;
        box-shadow: 0px 10px 40px rgba(157, 166, 171, 0.2);
        border-radius: 20px;
        padding: 32px 40px;
        display: flex;
        align-items: flex-start;

        &-title {
            @include fontProperty(400, 32px, 100%, $color_dark);
            padding: 10px 40px 10px 0;
            margin-right: 40px;

            border-right: 2px solid $color_grey;

            @media (max-width: 1080px) {
                margin-right: 0;
                border: 0;
                font-size: 20px;
                line-height: 100%;
                margin-bottom: 45px;
                text-align: center;
                padding: 0;
                br {
                    display: none;
                    
                }
            }

            
        }

        @media (max-width: 1080px) {
            flex-direction: column;
            align-items: center;
            max-width: 700px;
            margin: 0 auto;
        }

        @include below(768px) {
            bottom: -570px;
            max-width: 375px;
            margin: 0 auto;
            padding: 25px 20px;
        }

        @include below(400px) {
            max-width: 340px;
        }
    
        @include below(345px) {
            max-width: 310px;
        }
    }

    &__form {
        &-row {
            display: flex;
            align-items: center;

            @media (max-width: 1080px) {
                flex-wrap: wrap;
                justify-content: space-between;                
            }

            @include below(768px) {
                flex-direction: column;
            }
        }

        .form__label {
            margin-right: 30px;

            @include below(1320px) {
                margin-right: 15px;
            }

            @media (max-width: 1080px) {
                margin-right: 0;
                margin-bottom: 20px;
                width: 47%;

                input {
                    width: 100%;
                }
            }

            @include below(768px) {
                width: 100%;
            }
        }
        .form__btn {
            max-width: 260px;
            min-width: 260px;

            @include below(1320px) {
                max-width: 200px;
                min-width: 200px;
            }

            @include below(768px) {
                max-width: 100%;
                min-width: 100%;
            }
        }
    }
}