@import "main-bx";

.about {
    
    &__top {
        position: relative;
        background-image: url(/dist/img/content/bg2.webp);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 750px;
        padding-top: 90px;
        @media (max-width: 768px) {
            height: 640px;
            padding-top: 20px;
        }
    }

    &__title {
        text-align: left;
        position: relative;
        margin-bottom: 30px;
        &::before {
            content: "";
            width: 112px;
            height: 2px;
            background-color: #A7A9AC;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    &__modal {
        width: 100%;
        max-width: 700px;
        height: 600px;
        background: #FFFFFF;
        box-shadow: 0px 10px 40px rgba(157, 166, 171, 0.2);
        border-radius: 20px;
        padding: 50px;
        position: relative;
        overflow: hidden;


        &::before {
            content: "";
            position: absolute;
            bottom: -20%;
            left: -20%;
            width: 488px;
            height: 473px;
            z-index: 1;
            border: 5px solid #D1D3D4;
            opacity: 0.3;
            border-radius: 150px;

            @media (max-width: 768px) {
                left: -80%;
            }
        }

        @media (max-width: 768px) {
            padding: 25px 20px;
        }
    }

    &__bottom {
        background: radial-gradient(50% 50% at 50% 50%, #CB0040 0%, #A50034 79.69%);

        padding: 56px 0;

        @media (max-width: 768px) {
            padding: 40px 0;
        }
    }

    &__point {
        display: flex;
        justify-content: space-around;

        &-item {
            text-align: center;

            &:not(:last-child) {
                @media (max-width: 768px) {
                    margin-bottom: 40px;
                }
            }
        }

        &-num {
            @include fontProperty(800, 60px, 82px, $white);

            @media (max-width: 768px) {
                font-size: 40px;
                line-height: 100%;
                margin-bottom: 10px;
            }
        }

        &-rise {
            height: 2px;
            width: 120%;
            margin-left: -10%;
            background: 
                linear-gradient(
                    90deg, rgba(255,255,255,0) 0%, 
                    rgba(255,255,255,0.2976540958180147) 30%, 
                    rgba(255,255,255,1) 51%, 
                    rgba(255,255,255,0.300455216266194) 70%, 
                    rgba(255,255,255,0) 100%
                );
        }

        &-text {
            @include fontProperty(400, 20px, 120%, $white);
            margin-top: 16px;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            max-width: 300px;
        }
    }

    &__nav {
        @media (max-width: 768px) {
            position: absolute;
            bottom: 35px;
            left: 20px;
        }
    }

    &__slider {
        min-height: 300px;
        margin-bottom: 30px;

        p {
            @include fontProperty(400, 20px, 140%, #4D4D4F);
            &:not(:last-child) {
                margin-bottom: 10px;
            }

            @media (max-width: 768px) {
                font-size: 16px;
            }
        }
    }
}