// Utils
@import "modules/btn/btn";
@import "modules/form/form";
@import "modules/carousel/carousel.scss";
@import "modules/modal/modal";

@import "modules/header/header";
@import "modules/nav/nav";
@import "modules/section/section";


@import "modules/intro/intro";
@import "modules/service/service";
@import "modules/experience/experience";
@import "modules/painting-cost/painting-cost";
@import "modules/upload/upload";
@import "modules/about/about";
@import "modules/work/work";
@import "modules/promo/promo";
@import "modules/map/map";

@import "modules/footer/footer";
