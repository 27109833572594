@import "main-bx";

.map {
    position: relative;

    &__top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
        &-wrap {
            padding-top: 20px;
            display: flex;

            @media (max-width: 768px) {
                flex-direction: column;
                transform: translateY(-20px);
                padding-top: 0;
            }
        }

        @media (max-width: 768px) {
            position: relative;
        }
    }

    &__select {
        display: flex;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 10px 40px rgba(157, 166, 171, 0.2);
        border-radius: 20px;
        padding: 4px;
        margin-right: 20px;

        @media (max-width: 768px) {
            width: 100%;
            margin-bottom: 20px;
            margin-right: 0;
        }

        &-left {
            padding: 22px;
            margin-right: 8px;
        }
        &-pin {
            cursor: pointer;
            width: 90px;
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #FBFBFB;
            border-radius: 20px;
            svg {
                width: 50px;
                height: 50px;
                fill: #4D4D4F;
            }

            &.active {
                background: rgba(165, 0, 52, 0.1);

                svg {
                    fill: #A50034;
                }
            }

            @media (max-width: 768px) {
                margin-left: auto;
            }
        }

        &-text {
            @include fontProperty(400, 14px, 100%, $color_dark);
            margin-bottom: 12px;
            @media (max-width: 374px)  {
                font-size: 10px;
            }
        }

        &-tel {
            @include fontProperty(700, 20px, 100%, $color_dark);
            text-decoration-line: underline;

            @media (max-width: 374px)  {
                font-size: 14px;
            }
        }
    }
    
    &__container {
        height: 660px;
        @media (max-width: 768px) {
            height: 360px;
        }
        .ymaps-2-1-79-balloon {
            border: 1px solid #A7A9AC;
            box-sizing: border-box;
            border-radius: 2px;
            padding: 0;
        }

        .ymaps-2-1-79-balloon__layout {
            overflow: visible;
        }
        .ymaps-2-1-79-balloon__close {
            display: none;
        }

        .ymaps-2-1-79-balloon__content {
            padding: 0 !important;
            margin: 0 !important;
        }

        .ymaps-2-1-79-map-copyrights-promo {
            @media (max-width: 768px) {
                display: none;
            }
        }

        .ymaps-2-1-79-balloon__content > [class] {
            @media (max-width: 768px) {
                height: 320px !important;
            }
        }
    }

    &__address {
        font-style: normal;
        padding: 40px 46px 26px 26px;

        @media (max-width: 768px) {
            padding: 25px 20px;
        }

        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &-close {
            position: absolute;
            top: -10px;
            right: -10px;

            background: #FFFFFF;
            border: 1px solid #A7A9AC;

            width: 26px;
            height: 26px;
            border-radius: 50%;

            display: flex !important;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img {
                width: 20px;
                height: 20px;
            }

            @media (max-width: 768px) {
                top: 15px;
                right: 15px;
            }
        }

        &-img {
            
            width: 60px;
            height: 60px;
            position: absolute;
            top: -30px;
            left: 15px;
            border: 1px solid #A7A9AC;
            background-color: #FFFFFF;
            border-radius: 50%;

            @media (max-width: 768px) {
                position: relative;
                top: auto;
                left: auto;
                margin-bottom: 20px;
            }

            img {
                width: 60px;
                height: 60px;
            }
        }

        &-text {
            @include fontProperty(400, 16px, 100%, $color_dark);
            margin-bottom: 26px;
        }
        span {
            display: block;
        }

        &-phone {
            @include fontProperty(700, 20px, 100%, $color_dark);
            margin-bottom: 10px;
            display: block
        }

        &-time {
            @include fontProperty(400, 14px, 100%, $color_dark3);
            margin-bottom: 20px;
        }

        &-route {
            display: flex;
            align-items: center;
            @include fontProperty(700, 14px, 100%, $color_dark);
            letter-spacing: 0.05em;
            text-transform: uppercase;
            img {
                width: 26px;
                height: 26px;
                margin-right: 12px;
            }
        }
    }
}