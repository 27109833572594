@import "main-bx";

.painting-cost {
    margin: 50px 0;
    
    &__car {
        position: relative;
    }

    &__img {
        width: 100%;
        max-width: 970px;
        position: relative;
        margin: 0 auto;
    }

    &__over {
        position: absolute;
        top: 87px;
        left: 53px;

        @media (max-width: 1000px) {
            top: 60px;
            left: 50px;
            width: 88%;
        } 

        @media (max-width: 768px) {
            top: -40px;
            left: 21px;
            width: 88%;
        }

        @media (max-width: 374px) {
            top: -44px;
            left: 16px;
        }
    }

    &__details {
        cursor: pointer;
        opacity: 0;
        transition: all .2s linear;
        &.active {
            opacity: 0.5;
        }

        &.fixed {
            opacity: 1;
        }
    }

    &__sum {
        &.active {
            opacity: 0.3;
        }

    }

    &__dots {

        @media (max-width: 768px) {
            display: flex;
            flex-wrap: wrap;
            margin-top: -20px;
            padding-top: 20px;
        }

        &-item {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid rgba(165, 0, 52, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: absolute;
            background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 33.29%, rgba(255, 255, 255, 0) 100%);

            &.roof {
                top: 18%;
                left: 56%;
                
                & > div::before {
                    top: -48px;
                    height: 44px;
                }

                & > div::after {
                    top: -58px;
                }

                @media (max-width: 768px) {
                    top: auto;
                    left: auto;
                }
            
                .painting-cost__dots-circle {
                    @media (max-width: 768px) {
                        top: 20px;
                        left: 180px;
                    }
                }
            }

            &.baggage {
                top: 32%;
                right: 16%;

                & > div::before {
                    top: -46px;
                    height: 42px;
                }

                & > div::after {
                    top: -56px;
                }
                @media (max-width: 768px) {
                    top: auto;
                    right: auto;
                }

                .painting-cost__dots-circle {
                    @media (max-width: 768px) {
                        top: 45px;
                        right: 20px;                    
                    }
                }
            }

            &.door-1 {
                top: 60%;
                left: 40%;

                & > div::before {
                    top: 15px;
                    height: 115px;
                }

                & > div::after {
                    top: 130px;
                }

                @media (max-width: 768px) {
                    top: auto;
                    left: auto;
                }

                .painting-cost__dots-circle {
                    @media (max-width: 768px) {
                        top: 90px;
                        left: 130px;
                    }
                }
            }

            &.bumper-1 {
                top: 60%;
                left: 16%;

                & > div::before {
                    top: 15px;
                    height: 82px;
                }

                & > div::after {
                    top: 97px;
                }

                @media (max-width: 768px) {
                    top: auto;
                    left: auto;
                }

                .painting-cost__dots-circle {
                    @media (max-width: 768px) {
                        top: 85px;
                        left: 25px;
                    }
                }
            }

            &.wing-2 {
                top: 43%;
                right: 28%;

                & > div::before {
                    top: -133px;
                    height: 128px;
                }

                & > div::after {
                    top: -142px;
                }

                @media (max-width: 768px) {
                    top: auto;
                    right: auto;
                }

                .painting-cost__dots-circle {
                    @media (max-width: 768px) {
                        top: 60px;
                        right: 70px;
                    }
                }
            }

            &.door-2 {
                top: 55%;
                right: 40%;

                & > div::before {
                    top: 15px;
                    height: 128px;
                }

                & > div::after {
                    top: 142px;
                }

                @media (max-width: 768px) {
                    top: auto;
                    right: auto;
                }

                .painting-cost__dots-circle {
                    @media (max-width: 768px) {
                        top: 80px;
                        right: 120px;
                    }
                }
            }

            &.wing-1 {
                top: 44%;
                left: 28%;

                & > div::before {
                    top: -105px;
                    height: 100px;
                }

                & > div::after {
                    top: -115px;
                }

                @media (max-width: 768px) {
                    top: auto;
                    left: auto;
                }

                .painting-cost__dots-circle {
                    @media (max-width: 768px) {
                        top: 65px;
                        left: 80px;
                    }
                }
            }

            &.hood {
                top: 40%;
                left: 24%;

                & > div::before {
                    top: -37px;
                    height: 32px;
                }

                & > div::after {
                    top: -46px;
                }

                @media (max-width: 768px) {
                    top: auto;
                    left: auto;
                }

                .painting-cost__dots-circle {
                    @media (max-width: 768px) {
                        top: 55px;
                        left: 55px;
                    }
                }
            }

            &.bumper-2 {
                top: 58%;
                right: 14%;

                & > div::before {
                    top: 15px;
                    height: 80px;
                }

                & > div::after {
                    top: 95px;
                }

                @media (max-width: 768px) {
                    top: auto;
                    right: auto;
                }

                .painting-cost__dots-circle {
                    @media (max-width: 768px) {
                        top: 80px;
                        right: 20px;
                    }
                }
            }

            &.opacity {
                opacity: 0.5;
            }

            &.fixed {
                opacity: 1;
            }

            @media (max-width: 768px) {
                position: static;
                height: auto;
                width: auto;
                top: auto;
                left: auto;
                justify-content: flex-start;
                width: 50%;
                margin-bottom: 20px;
                border: 0;

            }

            &::before {
                content: "";
                @media (max-width: 768px) {
                    max-width: 10px;
                    min-width: 10px;
                    width: 100%;
                    height: 10px;
                    background: rgba(165, 0, 52, 0.2);
                    border: 1px solid #A50034;
                    margin-right: 10px;
                    border-radius: 50%;
                }
            }
        }

        &-circle {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #A50034;
            position: relative;
            &::before,
            &::after {
                content: "";
                position: absolute;
                display: block;
                @media (max-width: 768px) {
                    display: none;
                }
            }
            
            @media (max-width: 768px) {
                position: absolute;
                width: 14px;
                height: 14px;
                background-color: transparent;
                border: 1px solid #A50034;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            

            &::before {
                left: 5px;
                width: 1px;
                opacity: 0.5;
                background-color: #A50034;

                @media (max-width: 768px) {
                    width: 7px !important;
                    height: 7px !important;
                    position: relative;
                    display: block;
                    background-color: #A50034;
                    border-radius: 50%;
                    opacity: 1;
                    top: auto !important;
                    left: auto !important;
                    right: auto !important;
                }
            }

            &::after {
                left: 0.7px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: rgba(165, 0, 52, 0.2);
                border: 1px solid #A50034;
            }
        }
    }

    &__price {
        top: -70px;
        left: 20px;
        
        position: absolute;
        white-space: nowrap;


        @media (max-width: 768px) {
            position: relative;
            left: auto;
            top: auto;
        }
        
        &.door-1 {
            top: 120px;

            @media (max-width: 768px) {
                top: auto;
            }
        }

        &.door-2 {
            top: 130px;
            @media (max-width: 768px) {
                top: auto;
            }
        }
        
        &.bumper-1 {
            top: 90px;
            left: auto;
            right: 20px;
            text-align: right;

            @media (max-width: 768px) {
                top: auto;
                right: auto;
                text-align: left;
            }
        }

        &.hood {
            top: -55px;
            left: auto;
            right: 20px;
            text-align: right;

            @media (max-width: 768px) {
                top: auto;
                right: auto;
                text-align: left;
            }
        }

        &.wing-1 {
            top: -130px;
            @media (max-width: 768px) {
                top: auto;
            }
        }

        &.wing-2 {
            top: -150px;
            @media (max-width: 768px) {
                top: auto;
            }
        }

        &.bumper-2 {
            top: 90px;
            @media (max-width: 768px) {
                top: auto;
            }
        }

        span {
            display: block;
            @include fontProperty(400, 14px, 100%, $color_dark3);
            margin-bottom: 5px;
        }

        strong {
            @include fontProperty(800, 20px, 100%, $color_dark);
        }
    }
}