@font-face {
    @include fontImport(
        'NunitoSans',
        '../fonts/NunitoSans-Regular',
        400,
    )
}


@font-face {
    @include fontImport(
        'NunitoSans',
        '../fonts/NunitoSans-SemiBold',
        600,
    )
}

@font-face {
    @include fontImport(
        'NunitoSans',
        '../fonts/NunitoSans-Bold',
        700,
    )
}

@font-face {
    @include fontImport(
        'NunitoSans',
        '../fonts/NunitoSans-ExtraBold',
        800,
    )
}
