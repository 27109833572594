@import "main-bx";

.form {
    fieldset {
        padding: 0;
        margin: 0;
        border: 0;
    }

    &__label {
        position: relative;
        input,
        select,
        .select2-container--default .select2-selection--single {
            display: block;
            font-size: 16px;
            border-radius: 30px;
            background-color: $white;
            color: $color_dark;
            border: 1px solid #D1D3D4;
            padding: 17px 30px 13px;
            transition: all .2s linear;
            height: auto;
            &.error {
                border-color: #D10042;
                
                &::placeholder {
                    color: #D10042;
                }

                & + .form__label-place {
                    color: #D10042;
                }
            }        
        }

        &-place {
            position: absolute;
            white-space: nowrap;
            top: -6px;
            left: 25px;
            z-index: 100;

            font-size: 10px;
            line-height: 14px;
            color: #4D4D4F;
            padding: 0 4px;
            background-color: $white;
        }

        &::placeholder {
            color: rgba(131, 139, 149, 0.7);
        }

        .select2 {
            width: 100% !important;
        }

        .select2-container--default .select2-selection--single .select2-selection__rendered {
            line-height: inherit;
        }

        [aria-expanded="true"] {
            .select2-selection__arrow {
                transform: rotate(180deg);
            }
        }

        .select2-container--default .select2-selection--single .select2-selection__arrow  {
            background-image: url(/dist/img/svg/down.svg);
            height: 26px;
            position: absolute;
            top: 13px;
            right: 16px;
            width: 26px;
            transition: all .2s linear;
            b {
                display: none;
            }
        }

        .select2-container .select2-selection--single .select2-selection__rendered {
            padding: 0;
        }
    }

    &__upload {
        display: flex;
        align-items: center;
        position: relative;
        &-btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            width: 100%;
            padding: 18px 30px 15px;
            border: 1px dashed rgba(165, 0, 52, 0.2);
            border-radius: 55px;

            span {
                @include fontProperty(700, 14px, 19px, $color_Bred);
                letter-spacing: 0.05em;
                text-transform: uppercase;
            }
            img {
                width: 16px;
                height: 16px;
                margin-right: 10px;
                margin-top: -5px;
            }

            &.setter {
                padding: 0;
                justify-content: center;
                width: 60px;
                height: 60px;
            }
        }

        &-max {
            @include fontProperty(400, 14px, 130%, $color_dark2);
            margin-left: 20px;
            white-space: nowrap;

            &.error {
                color: #A50034;
                opacity: 1;
            }

            &_alert {
                font-size: 12px;
                opacity: 0;
                position: absolute;
                left: 0;
                bottom: -20px;
            }
        }
    }
    
    &__btn {
        width: 100%;
        padding: 18px 0 15px;
        display: block;
        text-align: center;
    }

    &__text {
        @include fontProperty(400, 11px, 15px, $color_slates);
        margin-top: 14px;

        @include below(768px) {
            text-align: center;
        }
    }

    &__check {
        display: flex;
        align-items: center;
        padding-bottom: 30px;

        @include fontProperty(400, 12px, 100%, #6D6E71);
        &.error {
            color: #A50034;

            a {
                color: #A50034;
            }
        }
        a {
            color: $color_dark;
            &:hover {
                text-decoration-line: underline;
            }
        }

        cursor: pointer;
        input:checked + .form__check-box {
            background: #A50034;
        }

        &-box {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 24px;
            max-width: 24px;
            margin-right: 12px;
            height: 24px;
            background: transparent;
            border-radius: 8px;
            border: 2px solid #A50034;
        }

        img {
            width: 17px;
            height: 12px;
        }
    }
} 

.select2-container--default .select2-search--dropdown .select2-search__field {
    border-radius: 30px;
    border-color: #D1D3D4 !important;
    padding: 10px;
}

.select2-results__option {
    padding: 10px 5px !important;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: transparent !important;
    color: #A50034 !important;
}

.select2-container--default .select2-results__option--selected {
    background-color: transparent !important;
}

.select2-dropdown {
    border: 0 !important;
    border-left: 1px solid #D1D3D4 !important;
    border-bottom: 1px solid #D1D3D4 !important;
    border-right: 1px solid #D1D3D4 !important;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    padding: 15px;
}

.select2-container--open .select2-dropdown--below {
    
}