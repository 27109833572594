@import "main-bx";

.carousel {
    margin-top: 40px;

    @media (max-width: 768px) {
        margin-top: 20px;
    }

    .slick-list {
        overflow: visible;

        @media (max-width: 768px) {
            overflow: hidden;
        }
    }

    .slick-slide {
        opacity: 0.2;

        &.slick-active {
            opacity: 1;
        }

        @media (max-width: 768px) {
            opacity: 1;
        }
    }

    &__item {
        margin: 0 30px;

        @media (max-width: 768px) {
            margin: 0;
        }
    }

    .slick-dots {
        bottom: -35px;
        li {
            width: 16px;
            height: 16px;
            background: #D1D3D4;
            opacity: 0.6;
            border-radius: 50%;
            margin: 0 8px;
            &.slick-active {
                opacity: 1;
                background: #A50034;
            }
        }
        
        button {
            display: none;
        }
    }
}

.carousel-nav {
    display: inline-flex;
    align-items: center;
    position: relative;
    z-index: 1000;

    background: #FFFFFF;
    border: 1px solid #D1D3D4;
    border-radius: 55px;

    button {
        padding: 18px 20px;
        &:first-child {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                top: 17px;
                right: 0;
                width: 1px;
                height: 20px;
                background-color:#D1D3D4;
            }
        }
        img {
            margin-bottom: -3px;
        }
    }
}