@import "main-bx";

.experience {
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        margin: -40px 0 0 -20px;
        padding-top: 50px;

        @media (max-width: 768px) {
            margin: -20px 0 0 0;
            padding-top: 30px;
            flex-direction: column;
        }
    }

    &__01 {
        @media (max-width: 768px) {
            order: 1;
        }
    }

    &__02 {
        @media (max-width: 768px) {
            order: 2;
        }
    }

    &__03 {
        @media (max-width: 768px) {
            order: 3;
        }
    }

    &__04 {
        @media (max-width: 768px) {
            order: 5;
        }
    }

    &__05 {
        @media (max-width: 768px) {
            order: 5;
        }
    }

    &__06 {
        @media (max-width: 768px) {
            order: 6;
        }
    }

    &__card {
        width: 100%;
        max-width: 280px;
        height: 280px;
        background: #FFFFFF;
        border: 1px solid #F1F2F2;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 25px;
        margin: 40px 0 0 20px;
        text-align: center;

        @media (max-width: 1060px) {
            max-width: 240px;
        }

        @media (max-width: 768px) {
            max-width: 100%;
            margin: 20px 0 0 0;
            flex-direction: row;
            align-items: flex-start;
            text-align: left;
            height: auto;
            padding: 20px;
        }

        img {
            display: flex;
            width: 60px;
            height: 60px;
            margin-bottom: 23px;

            @media (max-width: 768px) {
                width: 50px;
                height: 50px;
                margin-bottom: 0;
                margin-right: 20px;
            }
        }

        &-title {
            font-weight: 800;
            font-size: 16px;
            line-height: 100%;
            color: #212322;
            margin-bottom: 12px;
            
            @media (max-width: 768px) {
                margin-bottom: 8px;
            }
        }

        &-text {
            font-weight: 400;
            font-size: 12px;
            line-height: 120%;
            color: #212322;
        }
    }

    &__block {
        position: relative;
        width: 100%;
        max-width: 580px;
        margin: 40px 0 0 20px;

        @media (max-width: 1320px) {
            max-width: 490px;
        }

        @media (max-width: 1060px)  {
            max-width: 400px;
        }

        @media (max-width: 920px) {
            max-width: 100%;
            
            margin: 20px 0 0 0;
            &.first {
                height: 270px;
            }
        }

        @media (max-width: 768px) {
            &.first {
                height: 150px;
            }
        }
    }

    &__tag {
        position: absolute;
        top: 20px;
        left: 40px;
        z-index: 10;
        font-weight: 400;
        font-size: 36px;
        line-height: 120%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #6D6E71;
        padding: 40px 70px 85px 56px;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        border: 1px solid rgba(209, 211, 212, 0.3);

        @media (max-width: 768px) {
            font-size: 20px;
            top: 0;
            left: 0;
            padding: 25px 35px 64px 25px;
        }
    }

    &__year {
        position: absolute;
        right: 70px;
        bottom: 45px;
        z-index: 20;
        background: #F8F8F8;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        font-weight: 800;
        font-size: 70px;
        line-height: 120%;
        color: #212322;
    
        &-inner {
            position: relative;
            padding: 20px 50px;

            @media (max-width: 768px) {
                padding: 14px 37px 14px 25px;
            }
        }

        &::before {
            content: "";
            position: absolute;
            top: 37%;
            left: -7px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #A50034;
        }

        @media (max-width: 768px) {
            font-size: 44px;
            line-height: 120%;
            right: 0;
            bottom: 0;
        }
    }

    &__list {
        &-item {
            display: flex;
            align-items: center;
        
            &:not(:last-child) {
                padding-bottom: 35px;

                @media (max-width: 768px) {
                    padding-bottom: 15px;
                }
            }
        }

        &-num {
            position: relative;
            font-weight: 400;
            font-size: 60px;
            line-height: 120%;
            color: #212322;
            padding: 0 16px 0 30px;
            margin-right: 16px;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 82px;
                height: 44px;
                background: rgba(33, 35, 34, 0.03);
                border-top-right-radius: 20px;
                border-bottom-left-radius: 20px;
            }
        }

        &-text {
            font-weight: 400;
            font-size: 20px;
            line-height: 120%;
            color: #6D6E71;
            @media (max-width: 768px) {
                font-size: 14px;
                line-height: 120%;
            }
            span {
                font-weight: 700;
                color: #A50034;
                text-transform: uppercase;
            }
        }
    }
}