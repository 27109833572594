@import "main-bx";

.mfp-content {
    .modal {
        display: block;
    }
}

.modal {
    display: none;
    position: relative;
    width: 100%;
    max-width: 345px;
    background-color: $white;

    margin: 0 auto;
    padding: 30px 40px 50px;
    border-radius: 10px;

    &__title {
        @include fontProperty(800, 24px, 24px, $color_dark);
        text-align: center;
        padding-top: 20px;
        margin-bottom: 40px;
    }

    &__text {
        @include fontProperty(400, 16px, 140%, $color_dark);
        text-align: center;
        margin-bottom: 50px;
        padding: 0 30px;
    }

    &__form-input {
        display: block;
        width: 100%;
        input {
            text-align: center;
        }
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    &__form-file {
        span {
            // color: $color_green;
        }
    }
}