@import "main-bx";

.footer {
    background-color: #212322;
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        &_top {
            padding: 60px 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            
            @media (max-width: 768px) {
                padding: 25px 0 30px;
            }
        }

        &_bottom {
            padding: 47px 0 58px;

            @media (max-width: 768px) {
                flex-direction: column-reverse;
            }
        }
    }

    &__logo {
        width: 260px;
        height: 52px;

        @media (max-width: 768px) {
            width: 200px;
            height: 40px;
            margin-bottom: 24px;
        }
    }

    &__tel {
        @include fontProperty(800, 24px, 33px, $white);
        display: block;
        margin-left: auto;
        margin-right: 40px;

        @media (max-width: 768px) {
            margin: 0 0 10px 0;
        }
    }

    &__btn {
        padding: 18px 30px 15px;
    }

    &__text {
        @include fontProperty(400, 14px, 19px, #A7A9AC);
        &_link {
            margin-right: auto;
            margin-left: 15px;
            text-decoration-line: underline;

            @media (max-width: 768px) {
                margin: 10px 0;
            }
        }
    }

    &__social {
        display: flex;
        align-items: center;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 44px;
            height: 44px;
            border: 1px solid #4D4D4F;
            border-radius: 50%;
            transition: all .2s linear;
            &:hover {
                background-color: $color_Bred;
                border-color: $color_Bred;

                svg {
                    fill: #fff;
                }
            }
            &:not(:last-child) {
                margin-right: 14px;
            }
        }

        svg {
            height: 20px;
            width: 20px;
            fill: #A7A9AC;
        }
    }
}