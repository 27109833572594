@import "main-bx";

.nav {
    &__list {
        display: flex;
        align-items: center;

        @media (max-width: 1100px) {
            flex-direction: column;
            align-items: flex-end;
            padding: 0 50px;
        }

        @media (max-width: 420px) {
            align-items: center;
        }
    }

    &__list-item {
        &:not(:last-child) {
            margin-right: 24px;

            @media (max-width: 1440px) {
                margin-right: 18px;
            }

            @media (max-width: 1100px) {
                margin-right: 0;
            }
        }
        a {
            display: block;
            padding: 14px 0;

            @include fontProperty(700, 14px, 19px, $color_slates);
            letter-spacing: 0.05em;
            text-transform: uppercase;
            transition: all .2s linear;
            &:hover {
                color: $color_Bred;
            }
        
            @media (max-width: 1440px) {
                font-size: 12px;
            }
        }
    }
}